body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.timer {
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* justify-content: center; */
    /* width: 95%; */
    /* margin: 100px auto; */
    /* padding: 10px; */
    /* background: linear-gradient(25deg, #95e2ff, #5f79ff, #8ed5ff); */
    /* margin-right: 30px; */
    /* padding: 1px; */
    font-size: 150%;
    color: #fff;
    /* line-height: 1; */
    /* text-align: center; */
    /* font-size: 20px; */
}

.list-group-content {
    width: 60%;
    text-align: left !important;
}

.list-group-content-item-title {
    width: 30%;
    font-size: 300%;
    border: 0 !important;
}

.list-group-content-item-content {
    width: 100%;
    border: 0 !important;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
}

.list-group-content-item-spacer {
    width: 2.5%;
    border: 0 !important;
}

.born-guilty-card-body {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.sub-title {
    /* max-width: 60%; */
    z-index: 10;
    text-align: center;
    padding: 0;
    /* margin: 10rem 0 0; */
    position: relative;
    letter-spacing: .05em;
    color: #fff;
    font-family: Montserrat;
    font-size: 2vh;
}

@keyframes ease-in-out-anim {
    25% {
        color: #fcfdfd;
    }
    50% {
        color: #747372;
    }
    75% {
        color: #000a09;
    }
}
.home-content-section-header {
    color: #fff;
    font-size: 6vw;
    font-weight: 700;
    animation-name: ease-in-out-anim;
    animation-duration: 6s;
    animation-iteration-count: infinite;
}
.home-content-section-content {
    color: #fff;
    font-size: larger;
    font-weight: 100;
}
.home-content {
    margin-bottom: 5rem;
}

div.bg-dark,
nav.navbar.navbar-expand-lg.navbar-dark.bg-dark.sticky-top {
    background-color: black !important;
}

.list-group {
    font: optional;
    border: 0 !important;
}

@keyframes ease-in-out-anim-one-color {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-100px); }
    100% { transform: translateY(0); }
}
.team-section-header {
    color: #000;
    font-size: 6vw;
    font-weight: 700;
    animation-timing-function: linear;
    animation-name: bounce-1;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
@keyframes bounce-1 {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}

.team-section-empty-box {
    background-color: black;
    min-height: 4vh;
    max-height: 4vh;
    height: 4vh;
    min-width: 2vw;
    max-width: 2vw;
    width: 2vw;
}